import React from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "./legal.css"

const Legal = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const seo = {
    title: t("legal.seo.title"),
    description: t("legal.seo.description"),
    lang: language,
  }

  return (
    <Layout datos={seo}>
      <div className="cookies md:mt-32 flex flex-col justify-center items-center container max-w-4xl p-10 ">
        <div className="p-5 text-sm">
          <h1 className="text-gray font-bold underline pt-5 border-x-0 text-3xl">
            {t("legal.aviso")}
          </h1>
          <h2>{t("legal.datos")}</h2>
          <p dangerouslySetInnerHTML={{ __html: t("legal.p1") }}></p>
          <h2>{t("legal.usuarios")}</h2>
          <p> {t("legal.p2")} </p>
          <h2>{t("legal.uso")}</h2>
          <p> {t("legal.p3")} </p>
          <h2>{t("legal.proteccion")}</h2>
          <p> {t("legal.p4")} </p>
          <h2>{t("legal.propiedad")}</h2>
          <p> {t("legal.p5")} </p>
          <h2>{t("legal.exclusion")}</h2>
          <p> {t("legal.p6")} </p>
          <h2>{t("legal.modificaciones")}</h2>
          <p> {t("legal.p7")} </p>
          <h2>{t("legal.empleo")}</h2>
          <p> {t("legal.p8")} </p>
          <h2>{t("legal.enlaces")}</h2>
          <p> {t("legal.p9")} </p>
          <h2>{t("legal.derecho")}</h2>
          <p> {t("legal.p10")} </p>
          <h2>{t("legal.generalidades")}</h2>
          <p> {t("legal.p11")} </p>
        </div>
      </div>
    </Layout>
  )
}

export default Legal

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
