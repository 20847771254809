import React, { useState, useEffect } from "react"
import Nav from "../components/nav/Nav"
import Footer from "./footer/Footer"
import Cookies from "./Cookies"
import Seo from "./Seo"

const Layout = ({ children, datos }) => {
  const [verAviso, setVerAviso] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("Seaconnect Cookies ACEPTADAS")) {
      window.dataLayer.push({ event: "cookies-aceptadas" })
      setVerAviso(false)
    } else if (localStorage.getItem("Seaconnect Cookies RECHAZADAS")) {
      setVerAviso(false)
    } else {
      setVerAviso(true)
    }
  }, [])

  const agregarCookie = () => {
    //Esta linea añade el evento en la capa superior que activa la etiqueta de GTM.
    window.dataLayer.push({ event: "cookies-aceptadas" })
    localStorage.setItem("Seaconnect Cookies ACEPTADAS", true)
    setVerAviso(false)
  }

  const noAgregarCookie = () => {
    localStorage.setItem("Seaconnect Cookies RECHAZADAS", true)
    setVerAviso(false)
  }
  return (
    <>
      <Seo datos={datos} />
      <Nav />
      <main className="cursor-default">{children}</main>
      {verAviso ? (
        <Cookies
          agregarCookie={agregarCookie}
          noAgregarCookie={noAgregarCookie}
        />
      ) : null}

      <Footer />
    </>
  )
}

export default Layout
