import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import cambioIdioma from "./cambioIdioma"
import Seaconnectlogo from "../../assets/seaconnectlogoWhite.svg"
import BurgerMenu from "../../assets/burger.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Navegacion = () => {
  const { t } = useTranslation()
  const { languages, language, originalPath } = useI18next()

  const [alturaMenu, setAlturaMenu] = useState("translate-x-full")
  const [aspectoIdiomaActivo, setAspectoIdiomaActivo] = useState("")

  const menu = [
    "nosotros",
    "comunicaciones",
    "avit",
    "navegacion",
    "servicio",
    "contacto",
  ]

  useEffect(() => {
    if (language === "es") {
      setAspectoIdiomaActivo("odd:bg-opacity-100 even:bg-opacity-50")
    } else {
      setAspectoIdiomaActivo("odd:bg-opacity-50 even:bg-opacity-100")
    }
    window.addEventListener("scroll", handleNav)
  }, [language])

  const [navegacion, setNavegacion] = useState("")

  const handleNav = () => {
    const position = window.pageYOffset

    if (position >= 100) {
      setNavegacion("-translate-y-full")
    } else {
      setNavegacion("")
    }
  }

  const cierraMenu = () => {
    setAlturaMenu("translate-x-full")
  }

  const verMenu = () => {
    setAlturaMenu("")
  }

  return (
    <>
      <header className="xl:hidden bg-azul-dark flex flex-row justify-between items-center p-5 z-20 space-x-2 ">
        <Link
          to={t("rutas.home")}
          aria-label="Home page"
          className="flex justify-center items-center w-1/2"
        >
          <Seaconnectlogo alt="SeaConnectLogo" className="h-14 xl:w-full" />
        </Link>

        {/* SELECCION DE IDIOMAS */}
        <div className="flex flex-row justify-center items-center">
          {languages.map(lng => (
            <button
              key={lng}
              onClick={() => cambioIdioma(lng, originalPath)}
              aria-label="Seleccion de idioma"
              className={`${aspectoIdiomaActivo} flex justify-center items-center w-7 h-7 rounded bg-blanco text-azul-dark font-bold text-sm transition-all duration-100 mr-4 last-of-type:m-0`}
            >
              {lng}
            </button>
          ))}
        </div>
        <button
          className="flex items-center justify-center"
          type="button"
          aria-label="Menu de navegación"
          onClick={verMenu}
        >
          <BurgerMenu alt="burger menu" width="35" height="35" />
        </button>

        {/* NAVEGACION MOVIL */}
        <nav
          className={`${alturaMenu} fixed top-0 bottom-0 right-0  bg-negro flex flex-col p-6 transform transition-all duration-300 ease-in-out pt-20 opacity-95 z-40`}
          onClick={cierraMenu}
          onKeyDown={cierraMenu}
        >
          {menu.map((item, index) => (
            <Link
              key={index}
              to={t(`rutas.${item}`)}
              className="text-blanco px-3 py-1.5 border-b border-azulClaro border-opacity-50  before:w-1 before:h-1 before:bg-blanco before:block before:absolute before:-mx-3 before:mt-2"
              activeClassName="bg-azul"
            >
              {t(`navegacion.${item}`)}
            </Link>
          ))}
        </nav>
      </header>

      {/* HEADER DESKTOP */}
      <header
        className={`hidden xl:flex flex-col items-center justify-center w-full fixed top-0 z-50 py-4 bg-gradient-to-t from-azulClaro-dark to-azul-dark px-10 shadow-xl ${navegacion} transform transition-all duration-100`}
      >
        <section className="flex flex-row items-center justify-between container mx-auto max-w-8xl">
          <Link to={t("rutas.home")} aria-label="Home page">
            <Seaconnectlogo alt="SeaConnectLogo" className="md:w-52" />
          </Link>
          <div className="flex flex-row">
            {/* NAVEGACION DESKTOP */}
            <nav className="grid grid-cols-3 grid-rows-2">
              {menu.map((item, index) =>
                item !== "contacto" ? (
                  <AnchorLink
                    key={index}
                    to={t(`rutas.${item}`)}
                    className="text-sm lg:text-normal text-blanco flex items-center justify-center transition duration-100 p-2 border-l border-t border-azulClaro rounded-tr-none rounded-full px-3 xl:px-7 hover:bg-blanco hover:bg-opacity-20"
                    activeClassName="bg-azulClaro border-azulClaro"
                  >
                    {t(`navegacion.${item}`)}
                  </AnchorLink>
                ) : (
                  <Link
                    key={index}
                    to={t(`rutas.${item}`)}
                    className="text-sm lg:text-normal text-blanco flex items-center justify-center transition duration-100 p-2 border-l border-t border-azulClaro rounded-tr-none rounded-full px-3 xl:px-7 hover:bg-blanco hover:bg-opacity-20"
                    activeClassName="bg-azulClaro border-azulClaro"
                  >
                    {t(`navegacion.${item}`)}
                  </Link>
                )
              )}
            </nav>
            {/* SELECCION DE IDIOMAS */}
            <div className="flex flex-col justify-center items-center gap-y-2 ml-5">
              {languages.map(lng => (
                <button
                  key={lng}
                  onClick={() => cambioIdioma(lng, originalPath)}
                  aria-label="Seleccion de idioma"
                  className={`${aspectoIdiomaActivo} flex justify-center items-center w-6 h-6 rounded cursor-pointer text-sm   bg-blanco text-azul transition-all duration-100`}
                >
                  {lng}
                </button>
              ))}
            </div>
          </div>
        </section>
      </header>
    </>
  )
}

export default Navegacion
