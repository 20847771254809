import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

const Cookies = ({ agregarCookie, noAgregarCookie }) => {
  const { t } = useTranslation()

  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-center items-center z-50 bg-blanco bg-opacity-90 h-80 sm:h-48">
      <div className="container p-5 sm:flex flex-row sm:justify-center items-center sm:space-x-5">
        <p
          className="text-negro sm:w-7/12 "
          dangerouslySetInnerHTML={{ __html: t("avisoCookies.mensaje") }}
        ></p>

        <div className="flex space-x-2 sm:flex-col sm:space-x-0 items-center justify-center">
          <button
            className="flex justify-center w-full items-center text-xs px-5 py-3 mt-3 font-medium text-negro rounded-lg shadow border-2 border-negro hover:bg-negro hover:text-blanco transition"
            onClick={agregarCookie}
          >
            {t("avisoCookies.aceptar")}
          </button>
          <button
            className="flex justify-center w-full items-center text-xs px-5 py-3 mt-3 font-medium text-negro rounded-lg shadow border-2 border-negro hover:bg-negro hover:text-blanco transition"
            onClick={noAgregarCookie}
          >
            {t("avisoCookies.rechazar")}
          </button>
          <Link
            className="flex justify-center w-full items-center text-xs px-5 mt-3 font-medium text-negro underline transition"
            to={t("rutas.cookies")}
          >
            {t("avisoCookies.masInfo")}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Cookies
