import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import SeaconnectlogoWhite from "../../assets/seaconnectlogoWhite.svg"
import "./footer.css"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="w-full flex flex-col justify-center items-center bg-negro lg:mt-0 pt-10">
      <section className="container anchoM px-4">
        <div className="mx-auto flex flex-col items-stretch md:flex-row lg:px-5">
          <article className=" px-3 text-blanco w-full text-left lg:pl-10 lg:w-4/12">
            <h2 className="text-base font-bold uppercase mb-1 text-azulClaro">
              {t("footer.nombre")}
            </h2>
            <ul
              dangerouslySetInnerHTML={{ __html: t("footer.datos") }}
              className="textos mb-2"
            ></ul>
            <ul>
              <li className="text-base text-blanco text-opacity-90 cursor-default hover:text-azulClaro mb-4">
                <a href="mailto:info@seaconnect.es">info@seaconnect.es</a>
              </li>
            </ul>
          </article>

          <article className="px-3 text-blanco w-full text-left xl:mr-10 lg:w-4/12 xl:ml-4">
            <h2 className="text-base font-bold uppercase mb-1 cursor-default text-azulClaro">
              {t("footer.info")}
            </h2>
            <ul>
              <li className="textosLista">
                <Link to={t("rutas.contacto")}>{t("footer.infoDatos.0")}</Link>
              </li>
              <li className="textosLista">
                <Link to={t("rutas.cookies")}>{t("footer.infoDatos.1")}</Link>
              </li>
              <li className="textosLista">
                <Link to={t("rutas.legal")}>{t("footer.infoDatos.2")}</Link>
              </li>
            </ul>
          </article>

          <article className="px-3 text-blanco w-full lg:w-4/12 mt-10 sm:mt-0">
            <SeaconnectlogoWhite alt="logo USAIL" className="w-60 h-16" />
            <p
              className="text-blanco text-xs opacity-80 border-t pt-3 mt-3"
              dangerouslySetInnerHTML={{ __html: t("footer.usailTexto") }}
            />
          </article>
        </div>
        <article className="px-3 text-xs opacity-80 text-blanco w-full pt-4 sm:pt-0 sm:px-5 md:px-20 lg:px-0 mb-4 sm:text-center">
          <p
            className="inline-block sm:mt-2 sm:text-center"
            dangerouslySetInnerHTML={{ __html: t("footer.grupo") }}
          />
          <span> {new Date().getFullYear()}&nbsp; </span>
          <span>{t("footer.derechos")}&nbsp;</span>
        </article>
      </section>
    </footer>
  )
}

export default Footer
