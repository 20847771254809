import { navigate } from "gatsby"

const cambioIdioma = (lng, originalPath) => {
  if (originalPath === "/" && lng === "es") {
    navigate("/")
  } else if (originalPath === "/" && lng === "en") {
    navigate("/en/")
  }
  if (
    (originalPath === "/contacto/" && lng === "es") ||
    (originalPath === "/contact/" && lng === "es")
  ) {
    navigate("/contacto/")
  } else if (
    (originalPath === "/contacto/" && lng === "en") ||
    (originalPath === "/contact/" && lng === "en")
  ) {
    navigate("/en/contact/")
  }
  if (
    (originalPath === "/privacidad/" && lng === "es") ||
    (originalPath === "/privacity/" && lng === "es")
  ) {
    navigate("/privacidad/")
  } else if (
    (originalPath === "/privacidad/" && lng === "en") ||
    (originalPath === "/privacity/" && lng === "en")
  ) {
    navigate("/en/privacity/")
  }
  if (originalPath === "/legal/" && lng === "es") {
    navigate("/legal/")
  } else if (originalPath === "/legal/" && lng === "en") {
    navigate("/en/legal/")
  }
  if (originalPath === "/cookies/" && lng === "es") {
    navigate("/cookies/")
  } else if (originalPath === "/cookies/" && lng === "en") {
    navigate("/en/cookies/")
  }
}

export default cambioIdioma
